* {
  box-sizing: border-box;
}

body {
    background-color: gainsboro;
}

.pageContainer {
    display:flex;
    flex-direction: column;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
}

.bodyContainer {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 35px;
}

.mainHeader {
    font-family: "Courier New", Courier, monospace;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;       
}

.title {
    font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
}

.subtitle {
    font-size: 12px;
}

.instructions {
    background-color: #abb8c4;
    /* margin-top: 25px;*/
    width: 300px;
    margin-left: 15px;
    margin-right: 25px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.instrucText {
    padding-left: 15px;
    padding-right: 15px;
    font-family: "Courier New", Courier, monospace;
}

.userStatusBar {
    background-color: #abb8c4;
    /*margin-top: 25px;*/
    margin-left: 25px;
    margin-right: 15px;
    width: 200px;
    display:flex;
    flex-direction: column
}

.chatbox {
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.chatTitle {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Courier New", Courier, monospace;
    font-size: 13px;
    font-weight: bold;

}

.chatname {
    margin: 5px;
}

.chat {
    margin: 5px;
    width: 170px;
    height: 300px;
    overflow: scroll;
    display: flex;
    flex-direction: column-reverse;
    background-color: white;
    word-wrap: break-word;
}

.looper {
    margin-left: 25px;
    margin-right:25px;
    width: 900px;
    background-color: goldenrod;
    display:flex;
    flex-direction: column; 
    font-family: "Courier New", Courier, monospace;
}

.masterLoop {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.mainMasterLoop {
    height: 80px;
    width: 100%;
    background-color: darkslategray;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
}

.loops{
    display: flex;
    align-items: center;
    justify-content: center;
}

.loopList {
    list-style-type: none;
    margin: 0px;  
    display: flex;
    flex-direction: column;
    padding-left: 0;
    justify-content: center;
    align-items: center; 
    width:100%;      
}

.loopItem {
    animation: grow 500ms ease-in-out forwards;
    overflow: hidden;
    width: 100%;
}

@keyframes grow {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 200px;
        opacity: 1;
    }
}

.loopControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;   
    width: 100%;
    height: 80px;
}

.loopProgress {
    position: relative;
    width: 100%;
    height: 30px;
    background-color: tan;
}

.loopProgressWaveform {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loopProgressBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: maroon;
    opacity: 0.5;
    transition: width 250ms linear;
}

.recordingDot { 
    animation-name: flashRed;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 50%;
    display: inline-flex;
    margin:20px;
} 

@keyframes flashRed {
  0%   {background-color:red;}
  50%  {background-color:gray;}
}

.dot { 
    height: 25px;
    width: 25px;
    background-color: gray;
    border-radius: 50%;
    display: inline-flex;
    margin:20px;
} 

.button {
    height: 54px;
    width: 54px;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    border: 2px solid black;
    justify-content: center;
    align-items: center;
    margin:5px;
    border-radius: 27px;
}

.smallFont{
    font-size: 14px;
    text-align: center;
}

.toggled {
    background-color: brown;
}

.unavail {
    text-decoration: line-through;
    opacity: .5;
}

.flashing {
    animation-name: flashRed;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.slider {
    margin: 5px;
    padding: 5px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    padding-bottom: 15px;  
}

.sliderValBox {
    display: inline-flex;
}

.downarrow {
    background-image: url(./images/downarrow.png);
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    /*opacity: .4;      */
}

.uparrow {
    background-image: url(./images/uparrow.png);
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    /*opacity: .4;      */
}

.masterExtension {
    height: 0px;
    transition: height 500ms;
    overflow: hidden;
    display:flex;
    width: 100%;
    background-color: slategray;
    flex-direction: column; 
    justify-content: center;
    align-items: center;  
}

.visibleExtension{
    height: 110px;
}

.extensionControls {
    width:100%;
    height: 80px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.progressBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 30px;
    justify-content: center;
    align-items: center; 
}

.progressBarSlider {
    width: 80%;
}

.progressBarVal {
    display: inline-flex;
    font-size: 12px;
    margin: 5px;
}

.bpm {
    margin:10px;
    padding: 10px;
    border-radius: 25%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 43px;
    font-size: 14px;
    
}

.bpmInput {
    margin: 3px;
    background-color: gainsboro;
}

.maxReps {
    margin:10px;
    padding: 5px;
    border-radius: 25%;
    border: 2px solid black;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 43px;
    
}

.maxRepsInput {
    background-color: gainsboro;
}

.dropDownDiv {
    margin:10px;
    padding: 5px;
    font-size: 14px;
    border-radius: 10px;
    border: 2px solid black;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:55px;
}

.dropDown{
    appearance: none;
    border: 1px solid black;
    width: 75px;
    margin: 5px;
    background-color: gainsboro; 
}

.inputFont {
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
}

.initText {
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
    display: flex;
    flex-direction: column;
    background-color: gainsboro;    
    justify-content: center;
    align-items: center;
    padding-top:20px;
}

.initTextLine {
    margin: 10px;
    width: 550px;
    text-align: center;
}

.support {
    margin: 15px;
    text-align: right;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;   
    width: 200px; 
}

.support a {
    text-decoration: none;
    color: black;
}

.backHome {
    margin: 15px;
    text-align: left;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;  
    width: 200px;
}

.backHome a {
    text-decoration: none;
    color:black;
}

.loadingBox {
    display: flex;
    flex-direction: column;
    background-color: gainsboro;    
    justify-content: center;
    align-items: center;    
    padding-top: 10px;       
}

.loading {
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    font-size: 14px;
    width: 125px; 
    text-align: center;
}

.loadingoff {
    visibility: hidden;
}

.loadingon {
    animation-name: flashRed;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}